/**======================================================================
=========================================================================
Template Name: Wedash Bootstrap 5 Admin Template
Author: Techneinfosys
Support: [support link]
File: style.css
=========================================================================
=================================================================================== */

// main framework
$utilities: ();
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";
// stylelint-disable indentation

// Utilities
.site-header{
  .dropdown-menu{
    .dropdown-item{
      .drp-link{
        svg{
          margin-right: 0 !important;
          margin-left: 10px;
        }
      }
    }
  }
  .header-search {
    width: 65%;
    .input-group{
      input{
        padding: 0.575rem 1rem 0.575rem 3rem !important;
      }
    }
    .input-group .search-btn {
      left: 15px !important;
      right: auto !important;
    }
  }
}
select.form-select {
  padding-right: 40px;
}
$utilities: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
  (
    // scss-docs-start utils-vertical-align
    "align":
      (
        property: vertical-align,
        class: align,
        values: baseline top middle bottom text-bottom text-top,
      ),
    // scss-docs-end utils-vertical-align
    // scss-docs-start utils-float
    "float":
      (
        responsive: true,
        property: float,
        values: (
          start: right,
          end: left,
          none: none,
        ),
      ),
    // scss-docs-end utils-float
    // Opacity utilities
    // scss-docs-start utils-opacity
    "opacity":
      (
        property: opacity,
        values: (
          0: 0,
          25: 0.25,
          50: 0.5,
          75: 0.75,
          100: 1,
        ),
      ),
    // scss-docs-end utils-opacity
    // scss-docs-start utils-overflow
    "overflow":
      (
        property: overflow,
        values: auto hidden visible scroll,
      ),
    // scss-docs-end utils-overflow
    // scss-docs-start utils-display
    "display":
      (
        responsive: true,
        print: true,
        property: display,
        class: d,
        values: inline inline-block block grid table table-row table-cell flex inline-flex none,
      ),
    // scss-docs-end utils-display
    // scss-docs-start utils-shadow
    "shadow":
      (
        property: box-shadow,
        class: shadow,
        values: (
          null: $box-shadow,
          sm: $box-shadow-sm,
          lg: $box-shadow-lg,
          none: none,
        ),
      ),
    // scss-docs-end utils-shadow
    // scss-docs-start utils-position
    "position":
      (
        property: position,
        values: static relative absolute fixed sticky,
      ),
    "top": (
      property: top,
      values: $position-values,
    ),
    "bottom": (
      property: bottom,
      values: $position-values,
    ),
    "start": (
      property: right,
      class: start,
      values: $position-values,
    ),
    "end": (
      property: left,
      class: end,
      values: $position-values,
    ),
    "translate-middle": (
      property: transform,
      class: translate-middle,
      values: (
        null: translate(-50%, -50%),
        x: translateX(-50%),
        y: translateY(-50%),
      ),
    ),
    // scss-docs-end utils-position
    // scss-docs-start utils-borders
    "border":
      (
        property: border,
        values: (
          null: $border-width solid $border-color,
          0: 0,
        ),
      ),
    "border-top": (
      property: border-top,
      values: (
        null: $border-width solid $border-color,
        0: 0,
      ),
    ),
    "border-end": (
      property: border-left,
      class: border-end,
      values: (
        null: $border-width solid $border-color,
        0: 0,
      ),
    ),
    "border-bottom": (
      property: border-bottom,
      values: (
        null: $border-width solid $border-color,
        0: 0,
      ),
    ),
    "border-start": (
      property: border-right,
      class: border-start,
      values: (
        null: $border-width solid $border-color,
        0: 0,
      ),
    ),
    "border-color": (
      property: border-color,
      class: border,
      values:
        map-merge(
          $theme-colors,
          (
            "white": $white,
          )
        ),
    ),
    "border-width": (
      property: border-width,
      class: border,
      values: $border-widths,
    ),
    // scss-docs-end utils-borders
    // Sizing utilities
    // scss-docs-start utils-sizing
    "width":
      (
        property: width,
        class: w,
        values: (
          25: 25%,
          50: 50%,
          75: 75%,
          100: 100%,
          auto: auto,
        ),
      ),
    "max-width": (
      property: max-width,
      class: mw,
      values: (
        100: 100%,
      ),
    ),
    "viewport-width": (
      property: width,
      class: vw,
      values: (
        100: 100vw,
      ),
    ),
    "min-viewport-width": (
      property: min-width,
      class: min-vw,
      values: (
        100: 100vw,
      ),
    ),
    "height": (
      property: height,
      class: h,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto,
      ),
    ),
    "max-height": (
      property: max-height,
      class: mh,
      values: (
        100: 100%,
      ),
    ),
    "viewport-height": (
      property: height,
      class: vh,
      values: (
        100: 100vh,
      ),
    ),
    "min-viewport-height": (
      property: min-height,
      class: min-vh,
      values: (
        100: 100vh,
      ),
    ),
    // scss-docs-end utils-sizing
    // Flex utilities
    // scss-docs-start utils-flex
    "flex":
      (
        responsive: true,
        property: flex,
        values: (
          fill: 1 1 auto,
        ),
      ),
    "flex-direction": (
      responsive: true,
      property: flex-direction,
      class: flex,
      values: row column row-reverse column-reverse,
    ),
    "flex-grow": (
      responsive: true,
      property: flex-grow,
      class: flex,
      values: (
        grow-0: 0,
        grow-1: 1,
      ),
    ),
    "flex-shrink": (
      responsive: true,
      property: flex-shrink,
      class: flex,
      values: (
        shrink-0: 0,
        shrink-1: 1,
      ),
    ),
    "flex-wrap": (
      responsive: true,
      property: flex-wrap,
      class: flex,
      values: wrap nowrap wrap-reverse,
    ),
    "gap": (
      responsive: true,
      property: gap,
      class: gap,
      values: $spacers,
    ),
    "justify-content": (
      responsive: true,
      property: justify-content,
      values: (
        start: flex-end,
        end: flex-start,
        center: center,
        between: space-between,
        around: space-around,
        evenly: space-evenly,
      ),
    ),
    "align-items": (
      responsive: true,
      property: align-items,
      values: (
        start: flex-start,
        end: flex-end,
        center: center,
        baseline: baseline,
        stretch: stretch,
      ),
    ),
    "align-content": (
      responsive: true,
      property: align-content,
      values: (
        start: flex-end,
        end: flex-start,
        center: center,
        between: space-between,
        around: space-around,
        stretch: stretch,
      ),
    ),
    "align-self": (
      responsive: true,
      property: align-self,
      values: (
        auto: auto,
        start: flex-end,
        end: flex-start,
        center: center,
        baseline: baseline,
        stretch: stretch,
      ),
    ),
    "order": (
      responsive: true,
      property: order,
      values: (
        first: -1,
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        last: 6,
      ),
    ),
    // scss-docs-end utils-flex
    // Margin utilities
    // scss-docs-start utils-spacing
    "margin":
      (
        responsive: true,
        property: margin,
        class: m,
        values:
          map-merge(
            $spacers,
            (
              auto: auto,
            )
          ),
      ),
    "margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-end": (
      responsive: true,
      property: margin-left,
      class: me,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-start": (
      responsive: true,
      property: margin-right,
      class: ms,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    // Negative margin utilities
    "negative-margin":
      (
        responsive: true,
        property: margin,
        class: m,
        values: $negative-spacers,
      ),
    "negative-margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values: $negative-spacers,
    ),
    "negative-margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values: $negative-spacers,
    ),
    "negative-margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values: $negative-spacers,
    ),
    "negative-margin-end": (
      responsive: true,
      property: margin-left,
      class: me,
      values: $negative-spacers,
    ),
    "negative-margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values: $negative-spacers,
    ),
    "negative-margin-start": (
      responsive: true,
      property: margin-right,
      class: ms,
      values: $negative-spacers,
    ),
    // Padding utilities
    "padding":
      (
        responsive: true,
        property: padding,
        class: p,
        values: $spacers,
      ),
    "padding-x": (
      responsive: true,
      property: padding-right padding-left,
      class: px,
      values: $spacers,
    ),
    "padding-y": (
      responsive: true,
      property: padding-top padding-bottom,
      class: py,
      values: $spacers,
    ),
    "padding-top": (
      responsive: true,
      property: padding-top,
      class: pt,
      values: $spacers,
    ),
    "padding-end": (
      responsive: true,
      property: padding-left,
      class: pe,
      values: $spacers,
    ),
    "padding-bottom": (
      responsive: true,
      property: padding-bottom,
      class: pb,
      values: $spacers,
    ),
    "padding-start": (
      responsive: true,
      property: padding-right,
      class: ps,
      values: $spacers,
    ),
    // scss-docs-end utils-spacing
    // Text
    // scss-docs-start utils-text
    "font-family":
      (
        property: font-family,
        class: font,
        values: (
          monospace: var(--#{$variable-prefix}font-monospace),
        ),
      ),
    "font-size": (
      rfs: true,
      property: font-size,
      class: fs,
      values: $font-sizes,
    ),
    "font-style": (
      property: font-style,
      class: fst,
      values: italic normal,
    ),
    "font-weight": (
      property: font-weight,
      class: fw,
      values: (
        light: $font-weight-light,
        lighter: $font-weight-lighter,
        normal: $font-weight-normal,
        bold: $font-weight-bold,
        bolder: $font-weight-bolder,
      ),
    ),
    "line-height": (
      property: line-height,
      class: lh,
      values: (
        1: 1,
        sm: $line-height-sm,
        base: $line-height-base,
        lg: $line-height-lg,
      ),
    ),
    "text-align": (
      responsive: true,
      property: text-align,
      class: text,
      values: (
        start: right,
        end: left,
        center: center,
      ),
    ),
    "text-decoration": (
      property: text-decoration,
      values: none underline line-through,
    ),
    "text-transform": (
      property: text-transform,
      class: text,
      values: lowercase uppercase capitalize,
    ),
    "white-space": (
      property: white-space,
      class: text,
      values: (
        wrap: normal,
        nowrap: nowrap,
      ),
    ),
    "word-wrap": (
      property: word-wrap word-break,
      class: text,
      values: (
        break: break-word,
      ),
      rtl: false,
    ),
    // scss-docs-end utils-text
    // scss-docs-start utils-color
    "color":
      (
        property: color,
        class: text,
        local-vars: (
          "text-opacity": 1,
        ),
        values:
          map-merge(
            $utilities-text-colors,
            (
              "muted": $text-muted,
              "black-50": rgba($black, 0.5),
              // deprecated
              "white-50": rgba($white, 0.5),
              // deprecated
              "reset": inherit,
            )
          ),
      ),
    "text-opacity": (
      css-var: true,
      class: text-opacity,
      values: (
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1,
      ),
    ),
    // scss-docs-end utils-color
    // scss-docs-start utils-bg-color
    "background-color":
      (
        property: background-color,
        class: bg,
        local-vars: (
          "bg-opacity": 1,
        ),
        values:
          map-merge(
            $utilities-bg-colors,
            (
              "transparent": transparent,
            )
          ),
      ),
    "bg-opacity": (
      css-var: true,
      class: bg-opacity,
      values: (
        10: 0.1,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1,
      ),
    ),
    // scss-docs-end utils-bg-color
    "gradient":
      (
        property: background-image,
        class: bg,
        values: (
          gradient: var(--#{$variable-prefix}gradient),
        ),
      ),
    // scss-docs-start utils-interaction
    "user-select":
      (
        property: user-select,
        values: all auto none,
      ),
    "pointer-events": (
      property: pointer-events,
      class: pe,
      values: none auto,
    ),
    // scss-docs-end utils-interaction
    // scss-docs-start utils-border-radius
    "rounded":
      (
        property: border-radius,
        class: rounded,
        values: (
          null: $border-radius,
          0: 0,
          1: $border-radius-sm,
          2: $border-radius,
          3: $border-radius-lg,
          circle: 50%,
          pill: $border-radius-pill,
        ),
      ),
    "rounded-top": (
      property: border-top-left-radius border-top-right-radius,
      class: rounded-top,
      values: (
        null: $border-radius,
      ),
    ),
    "rounded-end": (
      property: border-bottom-left-radius border-top-left-radius,
      class: rounded-end,
      values: (
        null: $border-radius,
      ),
    ),
    "rounded-bottom": (
      property: border-bottom-right-radius border-bottom-left-radius,
      class: rounded-bottom,
      values: (
        null: $border-radius,
      ),
    ),
    "rounded-start": (
      property: border-top-right-radius border-bottom-right-radius,
      class: rounded-start,
      values: (
        null: $border-radius,
      ),
    ),
    // scss-docs-end utils-border-radius
    // scss-docs-start utils-visibility
    "visibility":
      (
        property: visibility,
        class: null,
        values: (
          visible: visible,
          invisible: hidden,
        ),
      )
      // scss-docs-end utils-visibility
  ),
  $utilities
);

@import "node_modules/bootstrap/scss/bootstrap";

// main framework
@import "themes/general";
@import "themes/generic";


// Layouts
@import "themes/layouts/sidenav";
@import "themes/layouts/site-header";
@import "themes/layouts/common";

// widget
@import "themes/component/widget";

// Componants
@import "themes/component/avtars";
@import "themes/component/tables";
@import "themes/component/forms";
@import "themes/component/buttons";
@import "themes/component/dropdowns";
@import "themes/component/progress";
@import "themes/component/modal";

// Plugins
@import "themes/plugin/data-tables";
@import "themes/plugin/rangeslider";
@import "themes/plugin/daterange";
@import "themes/plugin/switche";

// pages
@import "themes/pages/pages";
@import "themes/pages/ecommerce";
@import "themes/pages/authentication";




[dir="rtl"] {
  direction: rtl;
  text-align: right;
  body .theme-roller {
    right: auto;
    left: -300px;
    &.active {
      right: auto;
      left: 0;
    }
    .open-button {
      right: auto;
      left: 100%;
      .btn{
        border-radius: 0 8px 8px 0;
      }
    }
  }
  .rtl-hide{
    display: none;
  }
  .content-container {
    margin-left: 0;
    margin-right: $sidebar-width;
  }
  .site-header {
    left: 0;
    right: $sidebar-width;
    .header-menu-nav{
      .hdr-itm:not(:last-of-type){
        margin: 0 0 0 15px;
      }
    }
    .me-auto{
      margin-right: 0 !important;
    }
    .ms-auto{
      margin-left: 0 !important;
    }
  }
  .app-sidebar {
    .nav-submenu{
      .nav-link{
        .nav-arrow {
          float: left;
        }
      }
    }
    .nav-submenu {
      .nav-link {
        padding: 10px 70px 10px 30px;
      }
      .nav-submenu .nav-link {
        padding: 10px 85px 10px 30px;
      }
    }
    &.app-light-sidebar {
      .nav-link {
        .nav-icon {
          margin-right: 0;
          margin-left: 15px;
        }
      }
    }
  }
  .app-footer {
    margin-left: 0;
    margin-right: $sidebar-width;
  }

  .notification-modal .modal-dialog {
    margin: 0 auto 0 0;
  }
  .notification-modal.modal.fade:not(.show) .modal-dialog {
    transform: translate(-310px, 0);
  }
  body {
    &:not(.minimenu) {
      .app-sidebar {
        .nav-submenu {
          .nav-item:before {
            left: auto;
            right: 40px;
          }
          .nav-item:after{
            left: auto;
            right: 45px;
          }
          .nav-submenu > .nav-item:before {
            left: auto;
            right: 60px;
          }
          .nav-submenu > .nav-item:after {
            right: 65px;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .site-header {
      right: 0;
    }
    .app-sidebar {
      left: auto;
      right: -#{$sidebar-width};
      &.mob-sidebar-active {
        right: 0;
      }
    }
    .content-container {
      margin-left: 0;
      margin-right: 0px !important;
    }
    .app-footer{
      margin-right: 0 !important;
    }
  }
  // utility.
  $i: 0;
  @while $i<=50 {
    .p {
      &-l-#{$i} {
        padding-right: #{$i}px;
        padding-left: 0;
        @if ($i == 0) {
          &[class*="col"] {
            padding-left: 15px;
          }
        }
      }

      &-r-#{$i} {
        padding-left: #{$i}px;
        padding-right: 0;
        @if ($i == 0) {
          &[class*="col"] {
            padding-right: 15px;
          }
        }
      }
    }

    .m {
      &-l-#{$i} {
        margin-right: #{$i}px;
        margin-left: 0;
      }

      &-r-#{$i} {
        margin-left: #{$i}px;
        margin-right: 0;
      }
    }
    $i: $i + 5;
  }
  ol,
  ul,
  dl,
  .list-group,
  .list-unstyled {
    padding-right: 0;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    float: right;
  }
  .dropdown-menu {
    text-align: right;
  }
  .alert-dismissible {
    padding-right: $alert-padding-x;
    padding-left: 3.75rem;
    .btn-close {
      right: auto;
      left: 0;
    }
  }
  .accordion-button::after {
    margin-right: auto;
    margin-left: 0;
  }
  .dropdown-toggle {
    &::after {
      margin-right: 0.255em;
      margin-left: 0;
    }
  }
  .modal-header .btn-close {
    margin-right: auto;
    margin-left: 0;
  }
  .toast-header .btn-close {
    margin-right: 0.75rem;
    margin-left: -0.375rem;
  }
  .card .card-header:not(.border-0) h5:after,
  .card .card-header:not(.border-0) .h5:after {
    right: -25px;
    left: auto;
    border-radius: 3px 0 0 3px;
  }
  @media (min-width: 576px) {
    .card-group > .card:not(:first-child) {
      border-top-left-radius: $card-border-radius;
      border-bottom-left-radius: $card-border-radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .card-img-top {
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: 0;
      }
    }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: $card-border-radius;
      border-bottom-right-radius: $card-border-radius;
      .card-img-top {
        border-top-right-radius: $card-border-radius;
        border-top-left-radius: 0;
      }
    }
  }
  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
    &:last-child .page-link {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .card:not(.table-card) {
    .table {
      tr {
        td,
        th {
          &:first-child {
            padding-right: 25px;
          }

          &:last-child {
            padding-left: 25px;
          }
        }
      }
    }
  }
  .card .card-header .card-header-right {
    float: left;
    right: auto;
    left: 10px;
    .btn.dropdown-toggle {
      right: auto;
      left: 8px;
    }
  }
  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group {
    > :last-child {
      border-top-left-radius: $input-border-radius !important;
      border-bottom-left-radius: $input-border-radius !important;
    }
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      margin-left: 0;
      margin-right: -1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .btn-group {
    > .btn:nth-child(n + 3),
    > :not(.btn-check) + .btn,
    > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: $btn-border-radius;
      border-bottom-left-radius: $btn-border-radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .btn:first-child {
      border-top-right-radius: $btn-border-radius;
      border-bottom-right-radius: $btn-border-radius;
    }
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  select.form-control,
  select.custom-select,
  select.dataTable-selector,
  select.dataTable-input {
    background-position: 8px;
  }
  .form-check {
    padding-right: $form-check-padding-start;
    padding-left: 0;
    .form-check-input {
      float: right;
      margin-right: -#{$form-check-padding-start};
    }
  }
  .form-switch {
    padding-right: $form-switch-padding-start;
    padding-left: 0;
    .form-check-input {
      float: right;
      margin-right: -#{$form-switch-padding-start};
    }
  }
  .custom-switch-v1.form-switch {
    padding-right: 2.9em;
    .form-check-input {
      margin-right: -2.9em;
    }
  }
  .form-switch.form-switch-right {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
    .form-check-input {
      float: left;
      margin-left: 0;
    }
  }
  .choices[data-type*="select-multiple"] .choices__button,
  .choices[data-type*="text"] .choices__button {
    margin: 0 8px 0 -4px;
    border-left: none;
    border-right: 1px solid rgba(255, 255, 255, 0.35);
  }
  .form-send {
    .form-control {
      padding-left: 60px;
      padding-right: $input-padding-x;
    }
    .btn-send {
      left: 0;
      right: auto;
    }
  }
  .invoice-total {
    .table {
      td,
      th {
        text-align: left;
      }
      tbody {
        padding-right: 0;
        padding-left: 65px;
        float: left;
      }
    }
  }
  .dataTable-table > thead > tr > th {
    text-align: right;
  }
  .dataTable-sorter::before,
  .dataTable-sorter::after {
    left: 4px;
    right: auto;
  }
  div.dataTables_wrapper div.dataTables_filter {
    text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input{
  margin-left: 0;
  margin-right: 0.5em;
}
div.dataTables_wrapper div.dataTables_length select{
  text-align: left;
}
  .auth-wrapper {
    .auth-content .card .auth-img-content {
      margin: 0 auto 0 0;
    }
    &.auth-v2 {
      .bg-auth-side {
        left: 0;
        right: 50%;
        transform: skewX(8deg);
      }
    }
    &.auth-v3 {
      .bg-auth-side {
        left: 20px;
        right: 50%;
      }
    }
  }
  .btn-badge-position{
    .btn{
      margin-right: 0;
      margin-left: 20px;
    }
  }

}

